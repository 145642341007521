export const subjectPattern = [
    'ქართული ენა',
    'ქართული ლიტერატურა',
    'ქართული სტილისტიკა',
    'ქართული გრამატიკა',
    'ავტორის საათი',
    'ავტორის საათი / წერის საათი',
    'მათემატიკა',
    'ალგებრა / გეომეტრია',
    'ალგორითმიკა',
    'პრე-კალკულუსი',
    'კალკულუსი',
    'ბიზნესი / ეკონომიკა',
    'ბლოკური პროგრამირება',
    'ინგლისური ენა',
    'ინგლისური ლიტერატურა',
    'ინგლისური გრამატიკა და ლექსიკა',
    'ბიზნეს ინგლისური',
    'საკომუნიკაციო ინგლისური',
    'ინგლისური აკადემიური წერა',
    'გერმანული ენა',
    'რუსული ენა',
    'ფიზიკა',
    'ქიმია',
    'ბიოლოგია',
    'ისტორია',
    'ისტორია / ჩვენი საქართველო',
    'გეოგრაფია',
    'სამოქალაქო განათლება',
    'ინფორმაციული და საკომუნიკაციო ტექნოლოგიები',
    'რობოტიკა / ინჟინერია',
    'არტ ხელოვნება',
    'არტ სახელოსნო',
    'სამართალის შესავალი',
    'სასცენო ხელოვნება',
    'მუსიკა',
    'სპორტი'
]